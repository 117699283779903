/** Resize ADMIN */

html {
  font-size: 75%;
}

.header-navbar .navbar-container ul.nav li a.dropdown-user-link {
  padding: .7rem .6rem;
}

.header-navbar .navbar-header .navbar-brand {
  padding: 10px 0;
}

body.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-brand {
  padding: 10px 0;
}

// ADMIN

.admin-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      margin: 0 15px;
    }
  }
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-item: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 30px;

  .pages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .page {
      display: flex;
      margin: 0 3px;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background: #90a4ae;
      color:white;
      border-radius: 5px;

      &.active {
        background: #404E67;
      }

      &.active:hover {
        background: #404E67;
        color:white;
        cursor: default;
      }
    }
  }
}

.btn-unstyled {
  background: transparent;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.45;
  color: #404E67;
}

.notice {
  color: white;
}

.header-navbar .navbar-header .navbar-brand {
  .brand-logo {
    padding-left: 3px;
  }
  .brand-text {
    padding-left: 5px;
  }
}

.table {
  margin-bottom: 0;
  border: none;
}
.table-content {
  overflow-y: auto;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem;
}

.tabs-card-body {
  overflow-x: auto;
}

.card-filters {
  .card-body {
    padding: 15px;
  }
  .form-group {
    margin-bottom: 10px;
  }
}

.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
  border: 1px solid #ccd6e6;
}

.custom-control {
  display: initial;
}

.form-group .custom-control-label:after {
  content: '';
}

fieldset.form-group .custom-checkbox .custom-control-input ~ .custom-control-label.required::after {
  content: '*';
  left: -18px;
}
fieldset.form-group .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: '';
  left: -20px;
}

.custom-file-label::after {
  content: 'Parcourir';
}

.custom-control-label::after {
  top: 0px;
  left: -20px;
  width: 16px;
  height: 16px;
}

.col-form-label.required:after {
  content: '*';
  color: red;
  margin-left: 5px;
}

label[for="form_schema"]:after {
  content: '*';
  color: red;
  margin-left: 5px;
}

.list-group-flush:first-child .list-group-item:first-child {
  padding-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  padding-bottom: 0;
}

.nav.nav-tabs.nav-linetriangle .nav-item a.nav-link {
  background: #FFFFFF;
  text-transform: uppercase;
}
.nav.nav-tabs.nav-linetriangle {
  border-bottom-color: #26C0C3;
  margin-bottom: 20px;
  box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06);
}
.nav.nav-tabs.nav-linetriangle .nav-item a.nav-link.active:before {
  border-top-color: #26C0C3;
}
.nav.nav-tabs.nav-linetriangle .nav-item a.nav-link.active:focus {
  color: #26C0C3;
}
.nav.nav-tabs.nav-linetriangle .nav-item a.nav-link.active {
  border-bottom: 1px solid rgba(0,0,0,.1);
  border-bottom-color: #26C0C3;
  color: #26C0C3;
}

// We have to keep !important, because the theme use javascript to change the value.
.select2-container {
  width:100%!important;
}

label.required:after {
  content: ' *';
  color: red;
}

.bootstrap-datetimepicker-widget .fa-trash-o {
  pointer-events: all;
}

//remove event for the picker day/month
.day-month-picker .picker-switch {
  pointer-events: none;
}

// Important to keep all css related to the table-collection. Its used by the datepicker inside a table
.table-collection td {
  position: relative;
}

.table-collection .bootstrap-datetimepicker-widget tbody tr:nth-of-type(odd) {
  background-color: white!important;
}

.table-collection .datepicker-days th {
  padding: 0!important;
  border: none;
}

.table-collection .datepicker-days td {
  padding: 0!important;
  border: none;
}

// Input radio
.table-collection td > div {
  display: flex
}

.form-collection-element{
  border-bottom: 1px solid #ccc;
  padding-bottom: 50px;
  margin-bottom: 5px;
}

// ADMIN
// CONDITION
.condition-delete-button{
  padding-top:25px;
}

.condition-fields{
  padding-top: 23px;
}

.condition-fields .form-group{
  margin:0;
}

.condtion-promotion .form-group{
  margin:0;
}

.condtion-promotion .condition-type.form-group{
  margin-bottom: 1em;
}

.border-bottom{
  padding-bottom: 50px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1em;
}

// Styling button [+ / -] on input[type="number"] (from the theme)

.bootstrap-touchspin-up, .bootstrap-touchspin-down{
  border: 1px solid #ccc;
}

.bootstrap-touchspin-down{
  border-right:none;
}

.bootstrap-touchspin-up:hover, .bootstrap-touchspin-down:hover{
  background-color: #f0f0f0;
}

.list-group-item {
  padding: 8px 0;
  .container-fluid {
    padding: 0;
  }
  strong {
    font-size: 13px;
  }
}

input.form-control.touchspin {
  border-radius: 0;
}

.mtm {
  margin-top: 20px;
}

.subscription-active-indice {
    min-width: 10px;
    min-height: 51px;
    height: 100%;
    margin: 5px;
    background: #26C0C3;

    &.small {
        min-height: 30px
    }
}

.table thead th.column-active-indice {
    width:10px;
    padding:0;
    background:white;
    border:none;
}

.table-collection td.is_active {
    width:10px;
    padding:0;
}

.table .remove-column {
    padding: 10px 5px;
}

// Disabled select2.
.select-disabled .select2-container{
  pointer-events: none;
}

.select-disabled .select2-container .select2-selection{
  background-color: #ECEFF1;
}